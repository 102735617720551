import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';

export default {
	install(Vue) {
		const messengerHub = new Vue();
		Vue.prototype.$messengerHub = messengerHub;

		let connection = null;
		let startedPromise = null;
		let manuallyClosed = false;

		Vue.prototype.startSignalR = (jwtToken) => {
			connection = new HubConnectionBuilder()
			.withUrl(process.env.VUE_APP_MESSENGER_URI, 
					{ accessTokenFactory: () => jwtToken }
				)
				.configureLogging(LogLevel.Information)
				.build();

			// Forward hub events through the event, so we can listen for them in the Vue components
			connection.on('LiveChatMessageReceived', (payload) => {
				messengerHub.$emit('chat-message-received', payload);
			});

			connection.on('CreateChatGroup', (payload) => {
				messengerHub.$emit('chat-group-id-received', payload);
			});

			// You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
			// Docs recommend listening onclose and handling it there.
			// This is the simplest of the strategies
			function start() {
				startedPromise = connection.start()
					.catch(err => {
						console.error('Failed to connect with hub', err);
						return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
					})
				return startedPromise;
			}
			
			connection.onclose(() => {
				if (!manuallyClosed) start();
			})

			// Start everything
			manuallyClosed = false;

			start();
		}

		Vue.prototype.stopSignalR = () => {
			if (!startedPromise) return;

			manuallyClosed = true;
			return startedPromise
				.then(() => connection.stop())
				.then(() => { startedPromise = null });
		}

		// Provide methods for components to send messages back to server
		// Make sure no invocation happens until the connection is established
		messengerHub.sendMessage = (message) => {
			if (!startedPromise) return;

			return startedPromise
				.then(() => connection.invoke('SendMessage', message))
				.catch(console.error);
		}

		// Create chat to the application
		messengerHub.createChat = () => {
			if (!startedPromise) return;

			return startedPromise
				.then(() => connection.invoke('CreateChat'))
				.catch(console.error);
		}

		messengerHub.joinGroupChat = (topicId) => {
			if (!startedPromise) return;

			return startedPromise
				.then(() => connection.invoke('JoinChatGroup', topicId))
				.catch(console.error);
		}
	}
}