import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import LoadScript from 'vue-plugin-load-script';
import MessengerHub from './services/clients/messenger-hub';
import GoogleAuth from './sma/google-auth';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import IdleVue from "idle-vue";
import msal from 'vue-msal'

const eventsHub = new Vue();

Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY,
});
Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
  }
});
Vue.use(GoogleAuth, {
  clientId: process.env.VUE_APP_GOOGLE_AUTH_KEY,
  scope: 'profile email',
  prompt: 'select_account'
});
Vue.use(MessengerHub);
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCT,
  testMode: true,
});
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000, // 3600000, // 3 seconds
  startAtIdle: false
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
Vue.use(LoadScript);